import { createRouter, createWebHistory } from "vue-router";
import LayoutPublic from "../layouts/Public.vue";
import LayoutPublicMap from "../layouts/PublicMap";

import {
  routes as managerRoutes,
  middleware as managerMiddleware,
} from "@/views/manager/Routes";
import {
  routes as userRoutes,
  middleware as userMiddleware,
} from "@/views/user/Routes";

import store from "../store";

const routes = [
  {
    path: "/",
    name: "PublicMap",
    component: LayoutPublicMap,
    children: [
      {
        path: "",
        name: "PublicMapHome",
        component: () => import("../views/public/Home"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },
      {
        path: "/router",
        name: "PublicMapRouter",
        component: () => import("../views/public/Router"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },
    ],
  },

  {
    path: "/",
    name: "Public",
    component: LayoutPublic,
    children: [
      {
        path: "/report/:id",
        name: "PublicReport",
        component: () => import("../views/public/Report"),
      },
      {
        path: "/apis",
        name: "PublicApi",
        component: () => import("../views/public/Api.vue"),
      },

      {
        path: "/about",
        name: "PublicAbout",
        component: () => import("../views/public/About.vue"),
      },

      {
        path: "/group-list",
        name: "PublicGroupList",
        component: () => import("../views/public/GroupList.vue"),
      },

      {
        path: "/list-1",
        name: "PublicList1",
        component: () => import("../views/public/List1.vue"),
      },
      {
        path: "/place/:placeId",
        name: "PublicPlace",
        component: () => import("../views/public/Place"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },

      {
        path: "/privacy-policy",
        name: "PublicPrivacyPolicy",
        component: () => import("../views/public/PrivacyPolicy.vue"),
      },
    ],
  },

  {
    path: "/verify",
    name: "verify",
    component: LayoutPublic,
    children: [
      {
        path: "",
        name: "PublicVerify",
        component: () => import("../views/public/Verify"),
      },
    ],
  },

  ...managerRoutes,
  //...operatorRoutes,
  ...userRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => {
      /**
       * خیلی مهم:
       * میدلور هر لایه حساب کاربری باید به طوری جداگانه فراخوانی شود
       * و در زیر قرار بگیرد
       *
       **/
      // console.log(record);

      managerMiddleware(record, next, store.state.managerIsAuthenticated);
      //operatorMiddleware(record, next, store.state.operatorIsAuthenticated);
      userMiddleware(record, next, store.state.userIsAuthenticated);
    })
    /* eslint-disable */
  ) {
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
