export const routes = (parentName) => {
  return [
    {
      path: "setting/footer-information",
      name: parentName + "-" + "Setting-FooterInformation",
      component: () => import("./FooterInformation.vue"),
    },

    {
      path: "setting/symbols",
      name: parentName + "-" + "Setting-Symbols",
      component: () => import("./symbols/List.vue"),
    },

    {
      path: "setting/symbols/new",
      name: parentName + "-" + "Setting-Symbols-New",
      component: () => import("./symbols/Upsert.vue"),
    },
  ];
};
