import store from "@/store";
import layout from "@/layouts/PanelManager.vue";

import { routes as blogRoutes } from "./blog/Routes";
import { routes as managersRoutes } from "./managers/Routes";
import { routes as authRoutes } from "./auth/Routes";
import { routes as settingRoutes } from "./setting/Routes";
import { routes as preferencesRoutes } from "./preferences/Routes";
import { routes as analyticsRoutes } from "./analytics/Routes";
import { routes as historyRoutes } from "./history/Routes";
import { routes as routesReferrals } from "./referrals/Routes";
import { routes as routesUsers } from "./users/Routes";
import { routes as routesOrdersOffline } from "./ordersOffline/Routes";

const groupName =
  store.state
    .managerRoutesGroupName; /* نام گروه کابری برای مدیریت داشبرد یا پنل مورد نظر */
const pathRoot =
  store.state
    .managerRoutesPathRoot; /* مسیری ریشه‌ای که برای گروه کاربری در نظر گرفته‌ایم */
const parentName =
  store.state
    .managerRoutesParentName; /* نام اصلی برای زیر مسیر‌های مدیریت داشبرد یا پنل مورد نظر */
// ------- Routes -------- -------- -------- -------- -------- -------- -------- //
export const routes = [
  {
    name: parentName,
    path: pathRoot,
    component: layout,
    meta: {
      requiresAuth: true,
      group: groupName,
    },
    children: [
      ...managersRoutes(parentName),
      ...blogRoutes(parentName),
      ...settingRoutes(parentName),
      ...preferencesRoutes(parentName),
      ...analyticsRoutes(parentName),
      ...historyRoutes(parentName),
      ...routesReferrals(parentName),
      ...routesUsers(parentName),
      ...routesOrdersOffline(parentName),

      {
        path: "",
        redirect: {
          name: parentName + "-" + "Home",
        },
      },

      {
        path: "home",
        name: parentName + "-" + "Home",
        component: () => import("./Home.vue"),
      },

      {
        path: "donate",
        name: parentName + "-" + "Donate",
        component: () => import("./Donate.vue"),
      },

      {
        path: "message-list",
        name: parentName + "-" + "MessageList",
        component: () => import("./MessageList.vue"),
      },

      // -------- Language ------- ------- ------- ------- ------- ------- ------- //
      //  {
      //   path: "languages",
      //   name: parentName + "-" + "Languages",
      //   component: () => import("./Languages.vue"),
      // },
      // -------- END Language ------- ------- ------- ------- ------- ------- ------- //

      {
        path: "profile",
        name: parentName + "-" + "Profile",
        component: () => import("./Profile.vue"),
      },
    ],
  },

  // ####### Auth ####### ####### ####### ####### ####### ####### ####### ####### //
  /**
   * مهم:
   * چون لاگین و رجیستر از لایه پیشفرض گروه مدیریت پیروی نمی‌کنند باید خارج از فضای چیلدرن نوشته شود
   */

  ...authRoutes(parentName, pathRoot, groupName),
  // ####### END Auth ####### ####### ####### ####### ####### ####### ####### ####### //
];

// ------- END Routes -------- -------- -------- -------- -------- -------- -------- //

export const middleware = (record, next, checkAuth) => {
  // ------- Check Group ------- ------- ------- ------- ------- ------- ------- //
  if (record.meta.group === groupName) {
    // ------- Check Auth ------- ------- ------- ------- ------- ------- ------- //
    if (record.meta.requiresAuth) {
      console.log("SALAMA!");
      //const redirectPath = window.location.pathname; // برگشت به آدرس این صفحه
      if (checkAuth) {
        next();
      } else {
        next({
          name: `${parentName}-Login`,
          query: { redirect: window.location.pathname },
        });
      }
    }
    // ####### END Check Auth ####### ####### ####### ####### ####### ####### ####### //

    // ------- Check Auth Reverse ------- ------- ------- ------- ------- ------- ------- //
    else if (record.meta.requiresAuthReverse) {
      if (!checkAuth) {
        next();
      } else {
        next({
          name: `${parentName}-Login`,
        });
      }
    }
    // ####### END Check Auth  Reverse ####### ####### ####### ####### ####### ####### #######  //
  }
  // ####### END Check Group ####### ####### ####### ####### ####### ####### ####### //
};
