<template>
  <nav
    class="navbar navbar-expand-md navbar-dark fixed-top bg-dark"
    style="margin: unset !important; background-color: #000 !important"
  >
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarCollapse"
      aria-controls="navbarCollapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <img src="/assets/logo/logo-sq.svg?" style="height: 49px" alt="" />
    <label class="mx-4" style="color: #fff">پایگاه داده عمومی ایران</label>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <router-link
            :to="{ name: 'PublicMapHome' }"
            class="nav-link text-light"
          >
            <i class="bi bi-house mx-1"></i>
            {{ $t("home") }}
          </router-link>
        </li>

        <li class="nav-item">
          <router-link :to="{ name: 'PublicApi' }" class="nav-link text-light">
            <i class="bi bi-caret-left-fill mx-1"></i>
            {{ $t("APIs") }}
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            :to="{ name: 'PublicMapRouter' }"
            class="nav-link text-light"
          >
            <i class="bi bi-caret-left-fill mx-1"></i>
            {{ $t("مسیریاب") }}
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            :to="{ name: 'PublicGroupList' }"
            class="nav-link text-light"
          >
            <i class="bi bi-caret-left-fill mx-1"></i>
            {{ $t("reports") }}
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'PublicPrivacyPolicy' }"
            class="nav-link text-light"
          >
            <i class="bi bi-caret-left-fill mx-1"></i>
            {{ $t("privacy") }}
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            :to="{ name: 'PublicAbout' }"
            class="nav-link text-light"
          >
            <i class="bi bi-caret-left-fill mx-1"></i>
            {{ $t("about_us") }}
          </router-link>
        </li>

        <li class="nav-item">
          <a class="nav-link text-light" href="#">
            <i class="bi bi-caret-left-fill mx-1"></i>
            {{ $t("contact_us") }}
          </a>
        </li>
      </ul>
      <a
        href="/user/"
        class="btn btn-success my-2 my-sm-0"
        style="background-color: #219653; border-color: #219653"
      >
        <i class="bi bi-person"></i>
        ورود کاربران
      </a>
    </div>
  </nav>
</template>