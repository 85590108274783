import store from "../store";
import i18n from '../i18n'

const setLanguage = (lang) => {
  
    if (lang === 'fa') {
        store.commit("setDashboardLanguage", 'fa');
        store.commit("setDashboardDirection", 'rtl');
        $("html").attr("dir", "rtl");
        $("html").attr("lang", "IR_fa");
       // i18n.global.locale.value = 'en'
        i18n.global.locale = 'fa'

    } else if ('en') {
        store.commit("setDashboardLanguage", 'en');
        store.commit("setDashboardDirection", 'ltr');
        $("html").attr("dir", "ltr");
        $("html").attr("lang", "US_en");   
     //   i18n.global.locale.value = 'en'
        i18n.global.locale = 'en'

     
      
    }
};


export default setLanguage