export const routes = (parentName) => {
  return [
    {
      path: "web-service",
      component: () => import("./Index.vue"),
      children: [
        {
          path: "",
          name: `${parentName}-Web-Service`,
          component: () => import("./WebService.vue"),
          props: (route) => ({
            step: route.query.step,
          }),
        },
      ],
    },
  ];
};
