export const routes = (parentName) => {
  return [
    {
      path: "blog/new",
      name: parentName + "-" + "BlogNew",
      component: () => import("./Upsert.vue"),
    },
    {
      path: "blog",
      name: `${parentName}-BlogList`,
      component: () => import("./List.vue"),
      props: (route) => ({
        step: route.query.step,
      }),
    },

    {
        path: "blog/:id",
        name: parentName + "-" + "BlogView",
        component: () => import("./View.vue"),
      },
    {
        path: "blog/:id/edit",
        name: parentName + "-" + "BlogEdit",
        component: () => import("./Upsert.vue"),
      },
  ];
};
