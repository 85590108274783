export const routes = (parentName) => {
  return [
    {
      path: "users",
      component: () => import("./Index.vue"),
      children: [
        {
          path: "",
          name: `${parentName}-Users`,
          component: () => import("./List.vue"),
          props: (route) => ({
            step: route.query.step,
          }),
        },
        {
          path: "active",
          name: `${parentName}-Users-Active`,
          component: () => import("./List.vue"),
          props: (route) => ({
            step: route.query.step,
          }),
        },
        {
          path: "blocked",
          name: `${parentName}-Users-Blocked`,
          component: () => import("./List.vue"),
          props: (route) => ({
            step: route.query.step,
          }),
        },
        {
          path: "expired",
          name: `${parentName}-Users-Expired`,
          component: () => import("./List.vue"),
          props: (route) => ({
            step: route.query.step,
          }),
        },
        {
          path: "online",
          name: `${parentName}-Users-Online`,
          component: () => import("./ListOnline.vue"),
          props: (route) => ({
            step: route.query.step,
          }),
        },
        {
          path: "new",
          name: `${parentName}-Users-New`,
          component: () => import("./Upsert.vue"),
        },
        {
          path: ":id",
          name: parentName + "-" + "Users-View",
          component: () => import("./View.vue"),
        },
        {
          path: ":id/edit",
          name: parentName + "-" + "Users-Edit",
          component: () => import("./Upsert.vue"),
        },
      ],
    },
  ];
};
