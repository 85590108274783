<template>
  <div>
    <NavbarPublic />

    <div class="page-wrapper full-page" style="margin-top: 72px">
      <div
        class="page-content d-flex align-items-center justify-content-center"
      >
        <router-view :key="$route.fullPath" />
      </div>
    </div>
  </div>
</template>

<script>
import NavbarPublic from "@/components/public/NavbarPublic.vue";

export default {
  components: {
    NavbarPublic,
  },
  data() {
    return {
      userAgent: {},
    };
  },

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      this.userAgent = this.$root.getUserAgentDetails();
    },
  },

  mounted() {
    this.handleResize();
  },
};
</script>

<style scoped>
</style>