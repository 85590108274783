<template>
  <transition name="fade">
    <div
      v-if="loader_show"
      style="
        width: 100%;
        height: 100%;
        z-index: 5001;
        position: fixed;
        background-color: #0000007d;
      "
    >
      <div
        style="
          padding-top: 0px;
          padding-bottom: 0px;
          text-align: center;
          margin-top: 200px;
        "
      >
        <div
          class="spinner-border"
          style="width: 72px; height: 72px; color: #fff; border-width: 7px"
          role="status"
        >
          <span class="sr-only">لطفا صبر کنید</span>
        </div>
        <h3
          class="noselect_text mt-2"
          style="
            padding-top: 0px;
            padding-bottom: 0px;
            text-align: center;
            color: #fff;
            font-size: 28px;
            font-weight: 600;
            padding: 4pt;
          "
        >
          در حال دریافت داده‌ها <br />
          لطفا صبر کنید
        </h3>
      </div>
    </div>
  </transition>
</template>


<script>
export default {
  props: ["loader_show"],
  data() {
    return {
      //     loader_show: true
    };
  },
  methods: {
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
  },

  watch: {
    loader_show: {
      handler() {},
    },
  },

  mounted() {
    this.sleep(1000).then(() => {});
  },
};
</script>


<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>