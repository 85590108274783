<template>
  <div class="footer-bar">
    <router-link to="/" class="footer-bar-a">
      <i class="footer-bar-i bi bi-house"></i>
      <span class="footer-bar-span">خانه</span>
    </router-link>
    <router-link to="/router" class="footer-bar-a">
      <i class="footer-bar-i bi bi-signpost-2"></i>
      <span class="footer-bar-span">مسیریاب</span>
    </router-link>
    <router-link :to="{ name: 'PanelUser-Home' }" class="footer-bar-a">
      <i class="footer-bar-i bi bi-box-arrow-in-right"></i>
      <span class="footer-bar-span">ورود</span>
    </router-link>
    <router-link :to="{ name: 'PublicApi' }" class="footer-bar-a">
      <i class="footer-bar-i bi bi-cloud-arrow-down"></i>
      <span class="footer-bar-span">APIs</span>
    </router-link>
    <label @click="openModalLayerOPtions()" class="footer-bar-a">
      <i class="footer-bar-i bi bi-layers"></i>
      <span class="footer-bar-span">لایه‌ها</span>
    </label>
    <router-link to="/about" class="footer-bar-a">
      <i class="footer-bar-i bi bi-question-lg"></i>
      <span class="footer-bar-span">درباره</span>
    </router-link>
  </div>
</template>


<script>
export default {
  methods: {
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    openModalLayerOPtions() {
      $("#modal-layer-options").modal();
    },
  },
};
</script>