export default {
  "Email_or_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email or phone Number"])},
  "Enter_the_email_address_or_phone_number_you_used_when_you_joined_and_we'll_send_you_instructions_to_reset_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the email address or phone number you used when you joined and we'll send you instructions to reset your password"])},
  "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IR"])},
  "There_is_a_problem_Please_check_the_following_Inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a problem Please check the following Inputs"])},
  "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
  "account_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "activated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "add_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a camera"])},
  "add_new_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new camera"])},
  "add_new_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new card"])},
  "add_new_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new item"])},
  "add_new_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new user"])},
  "add_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Role"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
  "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert"])},
  "alert_activated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert activated"])},
  "alert_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert disabled"])},
  "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])},
  "all_managers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Managers"])},
  "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All users"])},
  "all_zones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Zones"])},
  "allowed_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed users"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics"])},
  "api_base_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API base url"])},
  "api_documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Documentation"])},
  "asphalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asphalt"])},
  "average_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Age"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "back_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back To Login"])},
  "bank_account_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank account information"])},
  "bank_melli_iran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Melli Iran"])},
  "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank name"])},
  "basic_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information"])},
  "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth date"])},
  "blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked"])},
  "blocked_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked users"])},
  "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
  "browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser"])},
  "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building"])},
  "buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buildings"])},
  "camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera"])},
  "camera_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera groups"])},
  "cameras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cameras"])},
  "cameras_in_this_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cameras in this zone"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "cancel_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel all"])},
  "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card number"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
  "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chart"])},
  "choose_an_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an option"])},
  "choose_your_desired_point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choose_your_desired_point"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common"])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
  "copyright_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright text"])},
  "create_your_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your account"])},
  "cut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cut"])},
  "dangerous_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dangerous warning"])},
  "dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "data_saved_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data saved successfully."])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "deactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivated"])},
  "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "delete_all_dossiers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all dossiers"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developer"])},
  "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device"])},
  "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])},
  "dictance_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dictance from"])},
  "dictance_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dictance to"])},
  "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
  "distance_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance From"])},
  "distance_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance To"])},
  "documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
  "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])},
  "dossiers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers"])},
  "download_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download options"])},
  "duplicate_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate events"])},
  "duplicate_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate intervals"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "editing_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing information"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "employee_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee code"])},
  "employee_contact_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee contact information"])},
  "employee_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee photo"])},
  "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees"])},
  "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
  "episods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Episods"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
  "event_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event level"])},
  "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Name"])},
  "event_point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Point"])},
  "event_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Threshold"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
  "expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration date"])},
  "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])},
  "expired_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired users"])},
  "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "extensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensions"])},
  "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
  "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Footer"])},
  "forget_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forget Password"])},
  "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form"])},
  "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
  "general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General settings"])},
  "good_bye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good Bye"])},
  "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello"])},
  "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
  "information_edited_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information edited successfully"])},
  "information_has_been_successfully_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information has been successfully registered"])},
  "installed_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installed version"])},
  "internal_validation_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal validation token"])},
  "job_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job position"])},
  "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "latest_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest events"])},
  "latest_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest version"])},
  "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License"])},
  "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light"])},
  "limits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limits"])},
  "line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line chart"])},
  "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
  "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
  "list_of_all_cameras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of all cameras"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "login_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login link"])},
  "login_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Page"])},
  "loginlogout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login-Logout"])},
  "main_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Title"])},
  "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
  "manager_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager area"])},
  "manager_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager dashboard"])},
  "managers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managers"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
  "map_iframe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map iframe"])},
  "material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Material"])},
  "meter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meter"])},
  "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
  "monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
  "national_card_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National card photo"])},
  "national_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National code"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "no_data_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "online_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online report"])},
  "online_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online users"])},
  "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator"])},
  "operator_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator Area"])},
  "operators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operators"])},
  "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
  "orders_offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders offline"])},
  "os": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OS"])},
  "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
  "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste"])},
  "pending_to_checking_Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending To Login"])},
  "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission"])},
  "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
  "personnel_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnel Code"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
  "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Places"])},
  "plaque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaque"])},
  "please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
  "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "quantum_random_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantum random numbers"])},
  "rando_number_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random Number Requests"])},
  "redo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redo"])},
  "referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral"])},
  "referrals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referrals"])},
  "register_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Page"])},
  "repeat_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
  "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
  "row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row"])},
  "salary_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary amount"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "script": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Script"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "search_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for"])},
  "search_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search in"])},
  "search_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search results"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
  "select_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select device"])},
  "select_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select event"])},
  "select_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select file"])},
  "select_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select language"])},
  "select_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select zone"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "sensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensor"])},
  "sensors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensors"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "shaba_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shaba number"])},
  "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
  "soil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soil"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "stone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stone"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supervisor"])},
  "support_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Text"])},
  "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbols"])},
  "symbols_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbols list"])},
  "system_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Information"])},
  "the_camera_has_been_registered_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The camera has been registered successfully"])},
  "the_device_has_been_registered_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The device has been registered successfully"])},
  "the_number_of_bits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of bits"])},
  "the_previous_token_was_invalidated_and_replaced_with_a_new_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The previous token was invalidated and replaced with a new token"])},
  "the_title_of_the_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The title of the links"])},
  "the_title_of_the_symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The title of the symbols"])},
  "the_user_was_activated_in_the_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user was activated in the system."])},
  "the_user_was_deactivated_in_the_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user was deactivated in the system."])},
  "the_zone_has_been_registered_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The zone has been registered successfully"])},
  "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "title_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title support"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undo"])},
  "upper_bound_of_the_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upper bound of the chart"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "user_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Area"])},
  "user_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Dashboard"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
  "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
  "view_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View list"])},
  "visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitors"])},
  "watch_lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch lists"])},
  "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water"])},
  "waterfall_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waterfall chart"])},
  "web_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Service"])},
  "webhook_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["webhook Title"])},
  "webhooks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "working_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working hours"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
  "zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone"])},
  "zone_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone Name"])},
  "zones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zones"])}
}