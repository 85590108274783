<template>
  <router-view :key="$route.fullPath" />
</template>

<script>
import PrPreLoaderFull from "@/components/custom/PrPreLoaderFull.vue";
import { setThemeDark, setThemeLight } from "@/utils/theme";
import { checkAuth } from "@/utils/checkAuth";
import axios from "axios";

import setLanguage from "@/utils/language";

export default {
  components: {
    PrPreLoaderFull,
  },
  data() {
    return {
      showPreLoad: true,
      show: false,
      repalce_string: {
        yes: "بلی",
        no: "نه",
        brand: "برند",
        "brand:en": "برند:en",

        air_conditioning: "تهویه هوا",

        clothes: "لباس",
        women: "زنانه",
        beauty: "زیبایی",

        branch: "شعبه",
        fax: "فکس",
        public: "عمومی",
        open: "باز",
        name: "نام",
        "name:en": "نام:en",
        old_name: "نام قدیمی",

        dispensing: "توزیع",

        facilities: "امکانات",
        drive_through: "خدمات رانندگی",

        name_loc: "نام محلی",
        loc_name: "نام محلی",
        wheelchair: "ویلچر",
        name_old: "نلم قدیمی",
        comment: "توضیح",
        emergency: "اورژانس",
        coronaquarantine: "قرنطینه کرونا",

        description: "شرح",
        opening_hours: "ساعات کار",
        "opening_hours:covid19": "ساعات کار:کرونا",
        operator: "اوپراتور",
        "operator:type": "اوپراتور:نوع",

        "payment:credit_cards": "پرداخت:کارت اعتباری",
        "payment:debit_cards": "پرداخت:کارت بدهی",
        "payment:ریال": "بلی",

        email: "ایمیل",

        "contact:phone": "تلفن",
        "contact:email": "ایمیل",
        "contact:telegram": "تلگرام",
        "contact:facebook": "فیس‌بوک",
        "contact:instagram": "اینستاگرام",

        capacity: "ظرفیت",
        delivery: "تحویل در محل",
        "diet:halal": "حلال",
        "diet:vegetarian": "گیاه خواری",
        outdoor_seating: "نشستن در فضای باز",

        phone: "تلفن",
        mobile: "موبایل",
        "phone:mobile": "موبایل",

        "addr:full": "نشانی:کامل",
        "addr:street": "نشانی:خیابان",

        address: "نشانی:کامل",
        "address:district": "نشانی:منطقه",
        "address:street": "نشانی:خیابان",
        "address:zone": "نشانی:ناحیه",
        "addr:flats": "نشانی:آپارتمان",
        "addr:floor": "نشانی:طبقه",

        region: "منطقه",
        zone: "ناحیه",
        office: "دفتر",
        diplomatic: "دیپلماتیک",

        "fuel:diesel": "سوخت:دیزل",
        "fuel:petrol": "سوخت:بنزین",
        "fuel:octane_91": "سوخت:اوکتان۹۱",
      },

      ltr_string: [
        "phone",
        "phone:mobile",
        "mobile",
        "email",
        "name:en",
        "contact:phone",
        "contact:email",
      ],
    };
  },
  methods: {
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },

    getUserAgentDetails() {
      const screenHeight = window.innerHeight;
      const screenWidth = window.innerWidth;
      const min_number = 992;

      let userAgentDetail = {
        screenHeight: screenHeight,
        screenWidth: screenWidth,
        screenType: "",
        device: "",
        os: "",
        browser: "",
      };

      if (window.innerWidth < min_number) {
        userAgentDetail["screenType"] = "small";
        userAgentDetail["mapWidth"] = screenWidth;
        userAgentDetail["mapHeight"] = screenHeight;
        userAgentDetail["mapBottom"] = 60;
      } else {
        userAgentDetail["screenType"] = "large";
        //userAgentDetail['map_width'] = screenWidth - 280;
        userAgentDetail["mapWidth"] = screenWidth;
        userAgentDetail["mapHeight"] = screenHeight - 60;
        userAgentDetail["mapBottom"] = 0;
      }

      let userAgent = navigator.userAgent;
      userAgentDetail["os"] = navigator.platform;

      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
        userAgentDetail["device"] = "tablet";
        userAgentDetail["screenType"] = "small";
        userAgentDetail["mapWidth"] = screenWidth;
        userAgentDetail["mapHeight"] = screenHeight;
        userAgentDetail["mapBottom"] = 60;

        if (screenWidth > screenHeight && screenWidth > min_number) {
          userAgentDetail["screen_type"] = "large";
          userAgentDetail["mapHeight"] = screenHeight - 60;
          userAgentDetail["mapBottom"] = 0;
        }
        /////
      } else if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          userAgent
        )
      ) {
        userAgentDetail["device"] = "mobile";
        userAgentDetail["screenType"] = "small";

        userAgentDetail["mapWidth"] = screenWidth;
        userAgentDetail["mapHeight"] = screenHeight;
        userAgentDetail["mapBottom"] = 60;
      }

      if (userAgent.split(",")[0] == "AndroidWebApk") {
        userAgentDetail["os"] = "Android";
        userAgentDetail["browser"] = userAgent.split(",")[0];
        userAgentDetail["manufacturer"] = userAgent.split(",")[1];
        userAgentDetail["model"] = userAgent.split(",")[2];
        userAgentDetail["version"] = userAgent.split(",")[3];
      } else if (userAgent.split(",")[0] == "IOSWebApp") {
        userAgentDetail["os"] = "IOS";
      }

      // console.log(userAgentDetail)
      return userAgentDetail;
    },

    setNeedJs() {
      this.sleep(1500).then(() => {
        HSCore.components.HSDatatables.init(".js-datatable");
        HSCore.components.HSTomSelect.init(".js-select");
        HSCore.components.HSQuill.init(".js-quill");

        const exportDatatable =
          HSCore.components.HSDatatables.getItem("exportDatatable");

        document
          .getElementById("export-copy")
          .addEventListener("click", function () {
            exportDatatable.button(".buttons-copy").trigger();
          });

        document
          .getElementById("export-excel")
          .addEventListener("click", function () {
            exportDatatable.button(".buttons-excel").trigger();
          });

        document
          .getElementById("export-csv")
          .addEventListener("click", function () {
            exportDatatable.button(".buttons-csv").trigger();
          });

        document
          .getElementById("export-pdf")
          .addEventListener("click", function () {
            exportDatatable.button(".buttons-pdf").trigger();
          });

        document
          .getElementById("export-print")
          .addEventListener("click", function () {
            exportDatatable.button(".buttons-print").trigger();
          });
      });
    },
  },

  watch: {},
  async created() {},

  mounted() {
    //this.setThemeDark();
    // this.setNeedJs()
  },
};
</script>

<style>
/**
  * Persian Number: Vazirmatn FD
  * English Number: Vazirmatn
  * English Number: Noto Sans Arabic
  */
.invalid-feedback {
  display: unset !important;
}

.list-checked-item::before {
  background-image: unset !important;
}

html {
  -moz-transition: left 1s ease 0.1s, width 0s ease-out 0.1s,
    border-width 0s ease-out 0.1s;
  -webkit-transition: left 1s ease 0.1s, width 0s ease-out 0.1s,
    border-width 0s ease-out 0.1s;
}

body {
  font-family: "Vazirmatn", sans-serif;
}

#app {
  font-family: "Vazirmatn", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.nav-link dropdown-toggle.router-link-exact-active {
  color: red;
}

.nav-link dropdown-toggle.router-link-exact-active:hover {
  color: green;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
td,
tr,
code,
strong,
small,
span,
button,
label {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.select2-container {
  width: 100% !important;
}

.select2-container--default {
  display: block;
  width: 100% !important;
  padding: 0.6125rem 2.25rem 0.6125rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #677788;
  background-color: #fff;

  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 13px 9px;
  border: 0.0625rem solid rgba(231, 234, 243, 0.7);
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select2-container--default .select2-selection--single {
  border: unset;
}

.select2-search__field {
  color: #1e2022;
  border: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;
  border-radius: 0.3125rem;
}

.select2-dropdown select2-dropdown--below {
  background-color: #fff;
  border: 0.0625rem solid rgba(231, 234, 243, 0.7);
  border-radius: 0.3125rem;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
}

.nav .nav-tabs li {
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d1cece;
}

.vpd-input-group input {
  line-height: 1.5 !important;
  padding: 0.6125rem 1rem !important;
}

.Vue-Toastification__toast-body {
  font-family: "Vazirmatn";
  direction: rtl;
  text-align: right;
  margin-left: 7px;
  margin-right: 7px;
}
.Vue-Toastification__toast--warning {
  color: var(--bs-gray-900) !important;
}

.vpd-icon-btn {
}

.leaflet-control-zoom {
  border: unset !important;
}

.leaflet-control-zoom-in {
  border-color: transparent !important;
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
}

.leaflet-control-zoom-out {
  border-color: transparent !important;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}
.leaflet-top {
  bottom: 14px !important;
}
.leaflet-left {
  margin-left: 28px !important;
}
</style>
