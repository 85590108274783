<template>
  <div>
    <NavbarPublic v-if="userAgent.screenType !== 'small'" />
    <FooterBarPublic v-if="userAgent.screenType === 'small'" />
    <router-view :key="$route.fullPath" />
  </div>
</template>
  
  <script>
import axios from "axios";
import Loader2 from "@/components/custom/Loader2.vue";

import NavbarPublic from "@/components/public/NavbarPublic.vue";
import FooterBarPublic from "@/components/public/FooterBarPublic.vue";

export default {
  components: {
    Loader2,
    NavbarPublic,
    FooterBarPublic,
  },
  data() {
    return {
      userAgent: {},
    };
  },

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      this.userAgent = this.$root.getUserAgentDetails();
    },
  },

  mounted() {
    this.handleResize();
  },
};
</script>
  
  <style scoped>
</style>