export const routes = (parentName) => {
    return [
      {
        path: 'preferences',
        name: `${parentName}-Preferences-Index`,
        component: () => import('./Index.vue'),
        redirect: {
            name: `${parentName}-Preferences-General`
        },
        children: [
            {
                path: 'general',
                name: `${parentName}-Preferences-General`,
                component: () => import('./General.vue')
            },
            {
                path: 'roles',
                name: `${parentName}-Preferences-Roles`,
                component: () => import('./Roles.vue')
            },
            {
                path: 'users',
                name: `${parentName}-Preferences-Users`,
                component: () => import('./Users.vue')
            },
            {
                path: 'users/new',
                name: `${parentName}-Preferences-NewUser`,
                component: () => import('./UpsertUser.vue')
            },
            {
                path: 'camera-groups',
                name: `${parentName}-Preferences-CameraGroups`,
                component: () => import('./CameraGroups.vue')
            },
            {
                path: 'watch-lists',
                name: `${parentName}-Preferences-WatchLists`,
                component: () => import('./WatchLists.vue')
            },
            {
                path: 'license',
                name: `${parentName}-Preferences-License`,
                component: () => import('./License.vue')
            },
            {
                path: 'verify',
                name: `${parentName}-Preferences-Verify`,
                component: () => import('./Verify.vue')
            },
            {
                path: 'documentation',
                name: `${parentName}-Preferences-Documentation`,
                component: () => import('./Documentation.vue')
            },
            {
                path: 'api-documentation',
                name: `${parentName}-Preferences-ApiDocumentation`,
                component: () => import('./ApiDocumentation.vue')
            },
            {
                path: 'webhooks',
                name: `${parentName}-Preferences-Webhooks`,
                component: () => import('./Webhooks.vue')
            }
        ]
      }
    ];
  };
  