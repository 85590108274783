const getUserAgentDetails = (minNumber=1200) => {
    let screenHeight = window.innerHeight;
    let screenWidth = window.innerWidth;
    let userAgentDetail = {
        screenHeight: screenHeight,
        screenWidth: screenWidth,
        screenType: "",
        device: "",
        os: "",
        browser: ""
    };

    if (window.innerWidth < minNumber) {
        userAgentDetail['screenType'] = 'small';
    } else {
        userAgentDetail['screenType'] = 'large'
    }
    let userAgent = navigator.userAgent;
    userAgentDetail['os'] = navigator.platform;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
        userAgentDetail['device'] = 'tablet';
        userAgentDetail['screenType'] = 'small';
        if (screenWidth > screenHeight && screenWidth > minNumber) {
            userAgentDetail['screenType'] = 'large'
        }
    } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent)) {
        userAgentDetail['device'] = 'mobile';
        userAgentDetail['screenType'] = 'small';
    }
    if (userAgent.split(',')[0] == 'AndroidWebApk') {
        userAgentDetail['os'] = "Android";
        userAgentDetail['browser'] = userAgent.split(',')[0];
        userAgentDetail['manufacturer'] = userAgent.split(',')[1];
        userAgentDetail['model'] = userAgent.split(',')[2];
        userAgentDetail['version'] = userAgent.split(',')[3];
    } else if (userAgent.split(',')[0] == 'IOSWebApp') {
        userAgentDetail['os'] = "IOS";
    }
    return userAgentDetail;
}

export default  getUserAgentDetails;