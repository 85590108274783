export const routes = (parentName) => {
  return [
    {
      path: "orders-offline",
      component: () => import("./Index.vue"),
      children: [
        {
          path: "",
          name: `${parentName}-OrdersOffline`,
          component: () => import("./List.vue"),
          props: (route) => ({
            step: route.query.step,
          }),
        },
        {
          path: "new",
          name: `${parentName}-OrdersOffline-New`,
          component: () => import("./Upsert.vue"),
        },
        //  {
        //   path: ":id",
        //   name: parentName + "-" + "Referrals-View",
        //   component: () => import("./View.vue"),
        //   },
        {
          path: ":id/edit",
          name: parentName + "-" + "OrdersOffline-Edit",
          component: () => import("./Upsert.vue"),
        },
      ],
    },
  ];
};
