import store from "../store";
export const setThemeLight = () => {
  store.commit("setDashboardTheme", "light");
  if ($("link[href='/assets/css/theme-dark.min.css']").length) {
    $('link[href="/assets/css/theme-dark.min.css"]').remove();
  }
  const link = document.createElement("link");
  link.setAttribute("rel", "stylesheet");
  link.setAttribute("href", "/assets/css/theme.min.css");
  document.head.appendChild(link);
};

export const setThemeDark = () => {
  store.commit("setDashboardTheme", "dark");
  if ($("link[href='/assets/css/theme.min.css']").length) {
    $('link[href="/assets/css/theme.min.css"]').remove();
  }
  const link = document.createElement("link");
  link.setAttribute("rel", "stylesheet");
  link.setAttribute("href", "/assets/css/theme-dark.min.css");
  document.head.appendChild(link);
};
